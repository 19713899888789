<section class="mt-5">
  <div class="container text-center text-lg-start">
    <div class="row-bootstrap">
      <div class="col-lg-8">
        <div class="row-bootstrap">
          <div class="col-lg-10">
            <h2>CCAS</h2>
            <h3 class="mt-4">CCAS Le Centre Communal d’Action Sociale</h3>
          </div>
          <div class="float-end col-lg-2 d-none d-lg-block ">
            <img class="img-fluid" src="assets/icons/element-graphique-point.svg" />
          </div>
        </div>
        <div class="text-center">
          <img  class="img-fluid" src="assets/images/sortir.jpeg">
        </div>
        <p>Le CCAS de Brécé, présidé par Mr Le Maire, est géré par un conseil d’administration constitué d’élus et de personnes œuvrant dans le domaine social.</p>
        <p>Le CCAS mène des actions sociales sur la commune : </p>
        <p>- à l’égard de personnes en situations difficiles : aides d’urgence, bons alimentaires, …</p>
        <p>- soutien de projets pour les jeunes (activités, séjours…) après étude du dossier et aussi vis à vis des séniors : organisation du repas de Noël, participation au voyage ANCV en partenariat avec les communes de Cesson-Sévigné, d’Acigné et de Thorigné.</p>
        <p class="mt-4 text-danger"><strong>Depuis le 15 janvier 2013, mise en place du dispositif "Sortir !" pour permettre l'accès à la culture et aux loisirs aux personnes à revenus modestes. </strong></p>
        <p  class="mt-4 text-danger"><strong>Informations à télécharger</strong></p>
        <div class="text-center">
          <button class="btn btn-dark">TÉLÉCHARGER LE DOCUMENT</button>
        </div>
      </div>
      <div class="col-lg-4 mt-lg-0 mt-5">
        <app-quick-access></app-quick-access>
      </div>
    </div>
  </div>

</section>
