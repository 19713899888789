import { Component, OnInit } from '@angular/core';
import { AidesService } from 'src/app/_services/aides.service';

@Component({
  selector: 'app-aides',
  templateUrl: './aides.component.html',
  styleUrls: ['./aides.component.scss']
})
export class AidesComponent implements OnInit {
  aides: any;
  constructor(private aideService: AidesService) { }

  ngOnInit(): void {
    this.getAides();
  }

  getAides() {
    this.aideService.getAides().subscribe(data => {
      this.aides = data.data;
    })
  }

}
