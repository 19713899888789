import { Component, OnInit } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { ArreteService } from 'src/app/_services/arrete.service';
import { CommonService } from 'src/app/_services/common.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-arretes',
  templateUrl: './arretes.component.html',
  styleUrls: ['./arretes.component.scss']
})
export class ArretesComponent implements OnInit {
  arretes: any;
  constructor(
    private arreteService: ArreteService,
    private commonService: CommonService,
    private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.getArretes();
  }

  getArretes() {
    this.arreteService.getArretes().subscribe(data => {
      this.arretes = data.data;
      this.arretes.sort((a, b) => b.id - a.id);
      this.commonService.changeData("");
      this.titleService.setTitle("Arrêtés municipaux");
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: 'Ma Commune', customSection: 'Publications',customTitle: 'Arrêtés municipaux'});
    })
  }

}
