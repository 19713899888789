import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-politique-confidentialite',
  templateUrl: './politique-confidentialite.component.html',
  styleUrls: ['./politique-confidentialite.component.scss']
})
export class PolitiqueConfidentialiteComponent implements OnInit {

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Politiques de confidentialité");
  }

}
