import { Component, OnInit, PipeTransform } from '@angular/core';
import { AssociationsService } from 'src/app/_services/associations.service';

@Component({
  selector: 'app-annuaire',
  templateUrl: './annuaire.component.html',
  styleUrls: ['./annuaire.component.scss']
})
export class AnnuaireComponent implements OnInit, PipeTransform {
  assos: any;
  constructor(private association: AssociationsService) { }

  ngOnInit(): void {
    this.getAssos();
  }

  getAssos() {
    this.association.getAssos().subscribe(data => {
      this.assos = data.data;
      this.transform(this.assos)
    })
  }

  transform(assos: any[]): any[] {
    return assos.sort((a, b) => a.nom.localeCompare(b.nom));
  }
}
