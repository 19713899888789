import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonService} from "../../../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { ElusService } from 'src/app/_services/elus.service';

@Component({
  selector: 'app-elus',
  templateUrl: './elus.component.html',
  styleUrls: ['./elus.component.scss']
})
export class ElusComponent implements OnInit {

  elus: any;
  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private elusService: ElusService) { }

  ngOnInit(): void {
    this.getElus();
  }

  getElus() {
    this.elusService.getAllElus().subscribe(data => {
      this.elus = data.data;
      this.commonService.changeData("");
      this.titleService.setTitle("Elus");
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customSection: "Ma commune", customRubrique: 'Conseil',customTitle: 'Elus'});
    })
  }
}
