import { Component, OnInit } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { BibService } from 'src/app/_services/bib.service';
import { CommonService } from 'src/app/_services/common.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-bib',
  templateUrl: './bib.component.html',
  styleUrls: ['./bib.component.scss']
})
export class BibComponent implements OnInit {

  bibs: any;
  constructor(private bibService: BibService,
    private commonService: CommonService,
    private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.getBibs();
  }

  getBibs() {
    this.bibService.getAllBib().subscribe(data => {
      this.bibs = data.data;
      this.commonService.changeData("");
      this.titleService.setTitle("Bib");
      this.bibs.sort((a, b) => {return new Date(b.dateCreation.date).getTime() - new Date(a.dateCreation.date).getTime()});
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: 'Ma Commune', customSection: 'Publications',customTitle: 'Bib'});
    })
  }
}
