import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.commonService.changeData("La page que vous demandez est introuvable.");
    this.titleService.setTitle("Page introuvable");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Page introuvable'});
  }

}
