<section class="mt-5">
  <div class="container text-center text-lg-start">
    <img class="content-points" src="assets/icons/element-graphique-point.svg" />
    <div class="row content">
      <div class="col-lg-8">
        <div class="row-bootstrap">
          <div class="col-lg-4 partner my-3"  *ngFor="let partner of partners">
              <img class="img-fluid"  src="{{ partner.image}}"  />
              <div class="mt-3">
                <p class="text-dark mb-2"><strong>{{ partner.name }}</strong></p>
                <div class="divider mb-2"></div>
                <p [innerHTML]="partner.description"></p>
              </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <app-quick-access></app-quick-access>
      </div>
    </div>
  </div>

</section>
