import { Component, OnInit } from '@angular/core';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { CommonService } from 'src/app/_services/common.service';
import { CrConseilService } from 'src/app/_services/cr-conseil.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-cr-conseil',
  templateUrl: './cr-conseil.component.html',
  styleUrls: ['./cr-conseil.component.scss']
})
export class CrConseilComponent implements OnInit {
  crConseils: any;
  constructor(
    private crService: CrConseilService,
    private commonService: CommonService,
    private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.getCr();
  }

  getCr() {
    this.crService.getAllCrConseil().subscribe(data => {
      this.crConseils = data.data;
      this.crConseils.sort((a, b) => b.id - a.id);
      this.commonService.changeData("");
      this.titleService.setTitle("PV du conseil");
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customSection: 'Ma Commune', customRubrique: 'Conseil',customTitle: 'PV du conseil'});
    })
  }
}
