import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";

@Component({
  selector: 'app-ccas',
  templateUrl: './ccas.component.html',
  styleUrls: ['./ccas.component.scss']
})
export class CcasComponent implements OnInit {
  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.commonService.changeData("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus justo lectus, quis maximus mi tincidunt ut. Praesent sit amet malesuada nisi. Aenean nec eleifend ex, suscipit aliquam augue. Ut commodo eros justo, vestibulum congue nisl volutpat at. Donec molestie.");
    this.titleService.setTitle("ACTION SOCIALE ET SANTÉ");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Action sociale et santé'});
  }

}
