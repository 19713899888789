import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";

@Component({
  selector: 'app-conseil',
  templateUrl: './conseil.component.html',
  styleUrls: ['./conseil.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ConseilComponent implements OnInit {

  partners: {image: string, name: string, description: string}[] = [
    {
      image: 'assets/images/conseil/lvc1b0wl.png',
      name: 'Christophe Chevance',
      description: 'Maire Urbanisme'
    },
    {
      image: 'assets/images/conseil/4li82kzs.png',
      name: 'Marie-Jo pédrono',
      description: '1ère Adjointe <br> Enfance - Jeunesse - Affaires Scolaires'
    },
    {
      image: 'assets/images/conseil/j9uxphzu.png',
      name: 'Philippe Boinet',
      description: '2ème Adjoint <br> Développement Durable - Environnement - Mobilités - Bâtiments Communaux'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-1.png',
      name: 'Marie-Odile Cadieu',
      description: '3ème Adjointe <br> Cohésion Sociale - Personnes âgées - Petite Enfance'
    },
    {
      image: 'assets/images/conseil/4li82kzs-1.png',
      name: 'Michel Poullaouec',
      description: '4ème Adjoint <br> Finances - Nouvelles Technologies'
    },
    {
      image: 'assets/images/conseil/j9uxphzu-1.png',
      name: 'Olivia Perche',
      description: '5ème Adjointe <br> Vie associative - Culture Communication'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-2.png',
      name: 'Thierry Jouan',
      description: 'Conseiller Municipal'
    },
    {
      image: 'assets/images/conseil/4li82kzs-2.png',
      name: 'Anne-Cécile Simonneaux',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/j9uxphzu-2.png',
      name: 'Bénédicte Beaudouin',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-3.png',
      name: 'Jean-François Bagouet',
      description: 'Conseiller Municipal'
    },
    {
      image: 'assets/images/conseil/4li82kzs-3.png',
      name: 'Alexandra Denis',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/j9uxphzu-3.png',
      name: 'Gildas Guélou',
      description: 'Conseiller Municipal'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-4.png',
      name: 'Christine Gallais',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/4li82kzs-4.png',
      name: 'Pascal Leroy',
      description: 'Conseiller Municipal'
    },
    {
      image: 'assets/images/conseil/j9uxphzu-4.png',
      name: 'Geneviève Leroux',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-5.png',
      name: 'Eric Maquigneau',
      description: 'Conseiller Municipale'
    },
    {
      image: 'assets/images/conseil/4li82kzs-5.png',
      name: 'Sarah Nadler',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/j9uxphzu-5.png',
      name: 'Guillaume Sourdril',
      description: 'Conseiller Municipale'
    },
    {
      image: 'assets/images/conseil/lvc1b0wl-6.png',
      name: 'Virginie Lehuger',
      description: 'Conseillère Municipale'
    },
    {
      image: 'assets/images/conseil/4li82kzs-6.png',
      name: 'Sylvain Guéné',
      description: 'Conseiller municipal suppléant'
    }
  ]

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.commonService.changeData("Renforcement des mesures de biosécurité pour lutter contre l'influenza aviaire dans les basses cours.");
    this.titleService.setTitle("Conseil");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Conseil'});
  }

}
