<app-search [show]="checked" (showToggle)="showToggle()"></app-search>

<nav class="navbar navbar-expand-lg sticky-top ">
  <div class="container">
    <div class="d-flex align-items-center justify-content-between w-100">
      <a href="/" class="navbar-brand">
        <img src="/assets/images/logo.png" height="70" alt="CoolBrand" class="d-none d-lg-block">
        <img src="/assets/images/logo.png" height="50" alt="CoolBrand" class="d-block d-lg-none">
      </a>
      <div class="d-lg-none d-block">
        <a href="/contact" class="btn p-0"><span class="fa fa-envelope text-white"></span></a>
        <button class="btn p-0"><a href="tel:+330299001009"><span class="fa fa-phone mx-4 text-black"></span></a>
        </button>
        <button class="btn p-0" (click)="showToggle()"><span class="fa fa-search  text-white"></span></button>
      </div>
      <div>

        <button type="button" class="navbar-toggle d-block d-lg-none" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span class="fa fa-bars text-white"></span>
        </button>
        <div class="d-none d-lg-block">
          <div class="navbar-nav px-5">
            <li class="nav-item {{ null != item ? 'dropdown' : '' }}" *ngFor="let item of menu">
              <a *ngIf="item.children != null" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ item.nom }}
              </a>
              <a *ngIf="item.children == null" class="nav-link" routerLink="{{item.nom}}">
                {{ item.nom }}
              </a>

              <ul *ngIf="item.children != null" class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li  *ngFor="let submenu of item.children" class=" second-level" >
                  <a class="dropdown-item" [routerLink]="['/'+item.slug, submenu.slug]">{{submenu.nom}}</a>
                  <ul  *ngFor="let thirdmenu of submenu.children"  >
                    <li *ngIf="thirdmenu.page !== null && thirdmenu.lien === null" class="third-level"><a class="text-decoration-none" [routerLink]="[item.slug, submenu.slug, thirdmenu.slug|lowercase]">{{thirdmenu.nom}}</a></li>
                    <li *ngIf="thirdmenu.lien !== null" class="third-level"><a class="text-decoration-none" [routerLink]="['/'+(thirdmenu.lien|lowercase)]">{{thirdmenu.nom}}</a></li>
                  </ul>
                </li>
              </ul>

            </li>
          </div>
        </div>
      </div>
      <div class="navbar-nav align-items-center d-lg-block d-none">
        <a href="/contact"><span class="fa fa-envelope text-white"></span></a>
        <a href="tel:+330299001009"><span class="fa fa-phone mx-4 text-white"></span></a>
        <span class="fa fa-search  text-white" (click)="showToggle()"></span>
      </div>
    </div>
    <div class="collapse navbar-collapse  nav-responsive" id="navbarCollapse">
      <div class="navbar-nav px-5">
        <li class="nav-item {{ null != item ? 'dropdown' : '' }}"  *ngFor="let item of menu"  >
          <a *ngIf="item.children != null" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ item.nom }}
          </a>
          <a *ngIf="item.children == null" class="nav-link" href="{{ menu.nom}}">
            {{ item.nom }}
          </a>

          <ul *ngIf="item.children != null" class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li  *ngFor="let submenu of item.children" class=" second-level" >
              <a class="dropdown-item" [routerLink]="['/'+item.slug, submenu.slug]">{{submenu.nom}}</a>
              <ul  *ngFor="let thirdmenu of submenu.children"  >
                <li *ngIf="thirdmenu.page !== null" class="third-level"><a class="text-decoration-none" [routerLink]="[item.slug, submenu.slug, thirdmenu.slug|lowercase]">{{thirdmenu.nom}}</a></li>
                <li *ngIf="thirdmenu.lien !== null" class="third-level"><a class="text-decoration-none" [routerLink]="['/'+(thirdmenu.lien|lowercase)]">{{thirdmenu.nom}}</a></li>
              </ul>
            </li>
          </ul>
        </li>
      </div>
    </div>
  </div>
</nav>
<div class="intro-image" [ngStyle]="{'background-image':' url(../../../assets/images/intro/intro.png)'}"></div>

<div class="intro-footer">
  <img src="assets/icons/vague-bleu-point-noir.svg">
  <div class="container p-3">
    <div class="d-flex">
      <div class="pe-3">
        <p class="pt-2">
          <span class="fa fa-circle text-white"></span>
          <span class="fa fa-circle ms-2 text-white"></span>
        </p>
      </div>
      <div>
        <h1>{{ this.titleService.getTitle() }}</h1>
      </div>
    </div>
    <p class="mt-3">{{ text }}</p>
  </div>
</div>
<div id="breadcrumb">
  <div class=" container breadcrumb text-lg-start text-center">
    <span class="text-danger">Vous êtes ici :</span>
    <app-ng-dynamic-breadcrumb  symbol=" • " bgColor="trasparent" fontSize="16px" lastLinkColor="#000" fontColor="#000" ></app-ng-dynamic-breadcrumb>
  </div>
</div>
<router-outlet></router-outlet>
