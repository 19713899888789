<section class=" mt-5">
  <div class="container">
    <img class="content-points" src="assets/icons/element-graphique-point.svg" />
    <div class="row content">
      <div class="col-lg-8">
        <div class="row-bootstrap">
          <div class="col-lg-6 partner my-3"  *ngFor="let item of events">
            <div class="partner-image">
                <div class="partner-image-overlay text-center">
                  <p class="mt-5">{{ item.from |date:'LLLL'}}</p>
                  <p>{{ item.from |date:'dd'}}</p>
                </div>
              <img class="img-fluid"  src="{{ item.image }}"  />
            </div>
            <p class="mt-3"><small><span class="text-warning">{{ item.category }}</span> <span class="float-end text-dark">{{ item.from|date:'dd/mm/yyyy' }}</span></small></p>
            <div class="clearfix"></div>
            <div class="mt-3">
              <p class="text-dark mb-2"><strong>{{ item.title }}</strong></p>
              <div class="divider mb-2"></div>
              <p>{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="filter p-5 ">
          <p class="pb-2">Filtrer les résultats par :</p>
          <form>
            <div class="form-group">
              <label class="mb-3">Catégorie :</label>
              <div>
                <select class="d-block w-100">
                  <option value="" disabled selected>Selectionnez une catégorie</option>
                </select>
              </div>
              <div class="form-group row my-3">
                <label for="from" class="col-sm-2 col-form-label">Du :</label>
                <div class="col-sm-10">
                  <input type="date" readonly class="form-control-plaintext" id="from"
                         [(ngModel)]="from" ngbDatepicker #d="ngbDatepicker"
                         (click)="d.toggle()"
                         [ngModelOptions]="{standalone: true}"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label for="to" class="col-sm-2 col-form-label">Au :</label>
                <div class="col-sm-10">
                  <input type="date" readonly class="form-control-plaintext" id="to"
                         [(ngModel)]="to" ngbDatepicker #f="ngbDatepicker"
                         (click)="f.toggle()"
                         [ngModelOptions]="{standalone: true}"

                  >
                </div>
              </div>
              <div>
                <button class="btn btn-dark d-block w-100 my-4">Valider</button>
                <button class="btn btn-outline-dark d-block w-100">PROPOSER UN ÉVÈNEMENT</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</section>
