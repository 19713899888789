<div class="quick-access p-5 ">
  <p class="pb-3">Accès rapide</p>
  <div class="row-bootstrap">
    <div class="col-6 text-center" *ngFor="let option of options">
      <a href="{{option.link}}" target="{{option.target}}">
        <img  class="img-fluid" src="{{option.image}}">
        <p>{{ option.name }}</p>
      </a>
    </div>
  </div>
</div>
