<div class="modal-body">
  <button type="button" id="btn-close-gallerie" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <div class="bar-cross-1"></div>
    <div class="bar-cross-2"></div>
  </button>
	<img class="img-slider" src="{{img.fileName}}">
  <div class="footer">
    <img class="vignette" *ngFor="let image of images" src="{{image.fileName}}" (click)="setImg(image)" alt="">
  </div>
</div>
