import { Component, OnInit } from '@angular/core';
import {News} from "../../_models/news";
import {CommonService} from "../../_services/common.service";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { TagService } from 'src/app/_services/tag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import {NgbDateStruct, NgbModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';
import { IntraMurosService } from 'src/app/_services/intra-muros.service';

@Component({
  selector: 'app-agenda-describe-intra-muros',
  templateUrl: './agenda-describe-intra-muros.component.html',
  styleUrls: ['./agenda-describe-intra-muros.component.scss']
})
export class AgendaDescribeIntraMurosComponent implements OnInit {
  event: any;
  tags: any;
  from: NgbDateStruct | null = null;
  to: NgbDateStruct | null = null;
  eventForm: any;
  title: any;
  id: any;
  slideConfig: any;
  sliders: any = [];
  url: any = [];

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private tagService: TagService, private intraMurosService: IntraMurosService, 
    private route: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal, private router: Router, 
    private ngbDateParser: NgbDateParserFormatter, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.slideConfig = { 
      autoplay: true, 
      autoplaySpeed: 3000, 
      prevArrow: '.prev-event-caroussel',  
      nextArrow: '.next-event-caroussel', 
      draggable: true, 
      dots: true, 
      infinite: true, 
      arrows: true
    };
    this.title = this.route.snapshot.paramMap.get('slug')
    this.id = this.route.snapshot.paramMap.get('id')
    this.eventForm = this.formBuilder.group({
      categorie: new FormControl(''),
      dateDebut: new FormControl(''),
      dateFin: new FormControl(''),
    });
    this.getEvent();
  }

  sanitized(link: string) {
    let safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    return safeUrl;
  }

  getEvent() {
    this.intraMurosService.getEvent(this.id).subscribe(async data => {
      this.event = data;
      if (this.event.url1 !== '') {
        this.url.push(this.event.url1);
      }
      if (this.event.url2 !== '') {
        this.url.push(this.event.url2);
      }
      if (this.event.url3 !== '') {
        this.url.push(this.event.url3);
      }
      
      this.sliders.push(this.event.image);
      this.event.gallery.forEach(element => {
        this.sliders.push(element['url']);
      });

      this.event.videos.forEach(element => {       
        element = "https://www.youtube.com/embed/"+element['id'];
        this.sliders.push(element);
      });

      this.event.category = [{
        id: this.event.category,
        name: await this.getCategoryName(this.event.category)
      }];
      
      this.commonService.changeData(data?.texte_vignette);
      this.titleService.setTitle(data.title);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: 'Mes Loisirs', customSection:'évènements', customTitle: data.title});
    })
  }
  async getCategoryName(id: number): Promise<any> {
    let categories: any;
    let categorie: any;
    return new Promise<any>((resolve, reject) => {
      this.intraMurosService.getCategory().subscribe(data => {
        categories = data;
        categorie = categories.find((el => el.id === id));
        return resolve(categorie.name);
      });
      
    })
  }
  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl'});
    modalCreate.componentInstance.type = type;
  }

  getTags() {
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
      this.getIMTags();
    })
  }

  async getIMTags() {
    await this.intraMurosService.getCategory().subscribe(data => {
      
      data.forEach(element => {
        this.tags.push({
          id: element.id,
          nom: element.name
        })
      });
      
    })
  }

  onSubmit(): void {
    let info = {
      'categorie': this.eventForm.get('categorie').value,
      'dateDebut': this.eventForm.get('dateDebut').value,
      'dateFin': this.eventForm.get('dateFin').value,
    }
    this.router.navigate(['mes-loisirs/evenements'], {queryParams: {'categorie': info.categorie, 'jourDebut': info.dateDebut.day, 'moisDebut': info.dateDebut.month, 'anneeDebut': info.dateDebut.year, 'jourFin': info.dateFin.day, 'moisFin': info.dateFin.month, 'anneeFin': info.dateFin.year}});
  }
}
