// redirections.ts

export const redirections = [
  { path: 'actualites.php', redirectTo: '/actualites', pathMatch: 'full' as const},
  { path: 'en-bref-actualites-63.html', redirectTo: '/actualites', pathMatch: 'full' as const},
  { path: 'actualites.php', redirectTo: '/actualites', pathMatch: 'full' as const},
  { path: 'vie-municipale-nous-contacter-70.html', redirectTo: '/contact', pathMatch: 'full' as const},
  { path: 'ma-commune/conseil/cr-conseil', redirectTo: '/ma-commune/conseil/pv-du-conseil', pathMatch: 'full' as const},
  { path: 'vie-municipale-comptes-rendu-conseil-3.html', redirectTo: '/ma-commune/conseil/pv-du-conseil', pathMatch: 'full' as const},
  { path: 'upload/fichier_23_1333179097.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_25_1333179281.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1318_1687442933.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_967_1542016045.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_35_1335891191.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1012_1557489748.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_812_1494486079.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1054_1580398948.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_9_1630940420.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_32_1333187851.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1026_1562238316.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1091_1581413533.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_885_1516024312.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_954_1538825773.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_841_1499351445.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_775_1505323441.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_493_1396779599.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_31_1333187793.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_184_1338285314.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1289_1660050871.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1302_1667638035.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1116_1593152689.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_429_1520851221.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_917_1527172147.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1006_1556611869.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_329_1361545319.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_933_1535371177.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_874_1510861468.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1312_1679307289.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_505_1400597592.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_628_1433851208.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_28_1398783134.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1204_1620647333.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_554_1412168322.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_840_1499351416.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1092_1581413583.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_839_1499351388.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_686_1457624969.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1200_1620646820.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_992_1552301218.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_974_1542960383.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_980_1546606980.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_490_1396087517.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_900_1521125228.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_195_1672652518.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1229_1626187208.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1281_1651150478.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_257_1349270913.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1093_1581413613.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1144_1604503469.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1159_1609418612.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_106_1334941751.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1208_1621060927.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_771_1482326614.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_969_1542098574.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1326_1693482873.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1216_1624118896.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_652_1446106867.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_398_1378287462.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1276_1651149109.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_6_1335698019.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_292_1503489706.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_141_1336058150.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_20_1333097574.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_663_1449666376.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1321_1687443593.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_8_1630940318.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_737_1474466404.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_233_1443514825.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1278_1651149806.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_845_1499691400.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_882_1516024222.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1095_1581414006.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_935_1531292769.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1272_1647242423.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_651_1444829199.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1280_1651150206.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_842_1499351474.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1008_1557142559.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1209_1621060947.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1041_1568043174.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1124_1594372109.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_303_1358865606.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_662_1456936154.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_968_1542098547.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_571_1417804530.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_641_1441349703.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1033_1562656968.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_772_1482326642.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1163_1609419079.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_226_1620747025.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_843_1499352823.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1296_1663314409.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_290_1355325550.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_310_1611935112.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_532_1408373529.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1327_1695126032.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1303_1667815565.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_760_1478682501.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1274_1647509265.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_847_1611935179.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_995_1553164495.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1084_1580199161.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1283_1651848671.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1089_1581409712.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_894_1518190205.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/fichier_1118_1593161262.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/flash-info/flash_info_24_2017_01_05.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/flash-info/flash_info_25_2017_01_19.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/flash-info/flash_info_012_2016_06_09_V2.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'upload/flash-info/flash_info_010_2016_05_12.pdf', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'vie-municipale-flash-infos-82.html', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'articles.php?ref=55', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'decouvrir-brece--brece-dans-les-medias-80.html', redirectTo: '/ma-commune/publications/flash-info', pathMatch: 'full' as const},
  { path: 'vie-municipale-equipements-communaux-10.html', redirectTo: '/ma-commune/services/equipements-communaux', pathMatch: 'full' as const},
  { path: 'vie-municipale-personnel-communal-5.html', redirectTo: '/ma-commune/services/personnels-communaux', pathMatch: 'full' as const},
  { path: 'vie-municipale-infos-utiles-92.html', redirectTo: '/ma-commune/territoire', pathMatch: 'full' as const},
  { path: 'decouvrir-brece--histoire-13.html', redirectTo: '/ma-commune/territoire/histoire', pathMatch: 'full' as const},
  { path: 'mentions-legales.php', redirectTo: '/mentions-legales', pathMatch: 'full' as const},
  { path: 'vie-quotidienne-culte-53.html', redirectTo: '/mes-demarches/etat-civil/mariage', pathMatch: 'full' as const},
  { path: 'sports-renforcement-musculaire-68.html', redirectTo: '/mes-loisirs/associations/annuaire-des-associations', pathMatch: 'full' as const},
  { path: 'sports-plaquette-associations-73.html', redirectTo: '/mes-loisirs/associations/annuaire-des-associations', pathMatch: 'full' as const},
  { path: 'culture-et-loisirs-animations-29.html', redirectTo: '/mes-loisirs/culture', pathMatch: 'full' as const},
  { path: 'culture-et-loisirs-aide-a-la-culture-et-aux-loisirs-90.html', redirectTo: '/mes-loisirs/culture', pathMatch: 'full' as const},
  { path: 'culture-et-loisirs-mediatheque-26.html', redirectTo: '/mes-loisirs/culture/mediatheque', pathMatch: 'full' as const},
  { path: 'culture-et-loisirs-espace-multimedia-59.html', redirectTo: '/mes-loisirs/culture/mediatheque', pathMatch: 'full' as const},
  { path: 'vie-quotidienne-entreprises-51.html', redirectTo: '/mon-quotidien/commerces', pathMatch: 'full' as const},
  { path: 'enfance-et-jeunesse-espace-jeunes-24.html', redirectTo: '/mon-quotidien/enfance-et-jeunesse', pathMatch: 'full' as const},
  { path: 'enfance-et-jeunesse-accueil-periscolaire-22.html', redirectTo: '/mon-quotidien/enfance-et-jeunesse', pathMatch: 'full' as const},
  { path: 'decouvrir-brece--plan-de-la-commune-12.html', redirectTo: '/mon-quotidien/se-deplacer/plan-de-brece', pathMatch: 'full' as const},
  { path: 'vie-municipale-urbanisme-9.html', redirectTo: '/mon-quotidien/urbanisme', pathMatch: 'full' as const},
];
