import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mon-quotidien',
  templateUrl: './mon-quotidien.component.html',
  styleUrls: ['./mon-quotidien.component.scss']
})
export class MonQuotidienComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
