<section class="mt-5">
    <div class="container text-center text-lg-start">
      <img class="content-points" src="assets/icons/element-graphique-point.svg" />
      <div class="row content">
        <div class="col-lg-8">
          <div class="row-bootstrap" *ngFor="let service of services">
            <h2 class="text-left service-title">
              <ul>
                <li>{{service.nom}}</li>
              </ul>
            </h2>
            <div class="col-lg-4 partner my-3"  *ngFor="let partner of service.personnels">
                <img class="img-fluid"  src="{{ partner.vignette}}"  />
                <div class="mt-3 bib-titre">
                  <p class="text-dark mb-2 personnel-title"><strong>{{partner.prenom}} {{ partner.nom }}</strong></p>
                  <div class="divider mb-2"></div>
                </div>


            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <app-quick-access></app-quick-access>
        </div>
      </div>
    </div>
</section>
