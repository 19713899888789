import { Component, OnInit, Input } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import { FormBuilder, FormControl } from '@angular/forms';
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { ContactService } from 'src/app/_services/contact.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() public type: any;

  contactForm: any;


  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService, private contactService: ContactService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  get f() { return this.contactForm.controls; }


  ngOnInit(): void {
    this.commonService.changeData("Mairie de Brécé nous contacter");
    this.titleService.setTitle("Nous contacter");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Nous contacter'});

    this.contactForm = this.formBuilder.group({
      message: new FormControl(''),
      nom: new FormControl(''),
      prenom: new FormControl(''),
      mail: new FormControl(''),
      tel: new FormControl(''),
      adresse: new FormControl(''),
      cp: new FormControl(''),
      ville: new FormControl(''),
    })
  }

  onSubmit() {
    this.spinner.show('spinnerContact');
    this.contactService.sendMail(this.type, this.contactForm.value).subscribe(data => {
      Swal.fire({
        toast: true,
        title: 'Votre demande a bien été prise en compte',
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        icon: 'success',
        timerProgressBar: true
      });
      this.spinner.hide('spinnerContact');
    },
    err => {
      Swal.fire({
        toast: true,
        title: 'Erreur',
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        icon: 'error',
        timerProgressBar: true
      })
      this.spinner.hide('spinnerContact');
    });
    // TODO: NOTIFICATION!
  }
}
