<section class=" mt-5">
  <div class="container">
    <img class="content-points" src="assets/icons/element-graphique-point.svg" />
    <div class="row content">
      <div class="col-lg-8">
        <div class="row-bootstrap">
          <div class="col-lg-6 partner my-3"  *ngFor="let item of actualites">
            <a *ngIf="!item.intra" class="link-actu" [routerLink]="[item.slug]" [state]="{id: item.id}">
              <img class="img-fluid"  src="{{ item.urlImage }}"  />
              <p class="mt-3"><small><span class="text-warning" *ngFor="let tag of item.tags">{{ tag.nom }} &nbsp;</span> <span class="float-end text-dark">{{ item.dateDebut.date|date:'dd/MM/yyyy' }}</span></small></p>
              <div class="clearfix"></div>
              <div class="mt-3">
                <p class="text-dark mb-2"><strong>{{ item.titre }}</strong></p>
                <div class="divider mb-2"></div>
                <p>{{ item.texte_vignette }}</p>
              </div>
            </a>
            <a *ngIf="item.intra" class="link-actu" [routerLink]="[item.id, item.slug]" [state]="{id: item.id}">
              <img class="img-fluid"  src="{{ item.urlImage }}"  />
              <p class="mt-3"><small><span class="text-warning" *ngFor="let tag of item.tags">{{ tag.nom }} &nbsp;</span> <span class="float-end text-dark">{{ item.dateDebut.date|date:'dd/MM/yyyy' }}</span></small></p>
              <div class="clearfix"></div>
              <div class="mt-3">
                <p class="text-dark mb-2"><strong>{{ item.titre }}</strong></p>
                <div class="divider mb-2"></div>
                <p>{{ item.texte_vignette }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="filter p-5 ">
          <p class="pb-2">Filtrer les résultats par :</p>
          <form [formGroup]="categorieForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="mb-3">Catégorie :</label>
              <div>
                <select class="d-block w-100" formControlName="categorie">
                  <option value="" disabled selected>Selectionnez une catégorie</option>
                  <option *ngFor="let tag of tags" value="{{tag.id}}">{{tag.nom}}</option>
                </select>
              </div>
              <div>
                <button type="submit" class="btn btn-dark d-block w-100 my-4">Valider</button>
                <button (click)="openPopUpPage('actualite')" class="btn btn-outline-dark d-block w-100">PROPOSER UNE ACTUALITÉ</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</section>
