import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private data = new BehaviorSubject({title : '', image : ''});
  data$ = this.data.asObservable();

  changeData(title: string, image: string = '../../../assets/images/intro/intro.png') {
    this.data.next({title: title, image: image})
  }
}
