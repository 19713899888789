import { Component, OnInit } from '@angular/core';
import { PersonnelService } from 'src/app/_services/personnel.service';
import { ServicesService } from 'src/app/_services/services.service';

@Component({
  selector: 'app-personnels',
  templateUrl: './personnels.component.html',
  styleUrls: ['./personnels.component.scss']
})
export class PersonnelsComponent implements OnInit {
  personnels: any;
  services: any;
  constructor(private persoService: PersonnelService, private servicesService: ServicesService) { }

  ngOnInit(): void {
    // this.getAll();
    this.getServices()
  }

  getServices() {
    this.servicesService.getAll().subscribe(data => {
      this.services = data;
      this.services.forEach(element => {
        element.personnels.sort((a,b) => a.ordre - b.ordre);
      });
    });
  }
  // getAll() {
  //   this.persoService.getAll().subscribe(data=>{
  //     this.personnels = data.data;
  //   })
  // }

}
