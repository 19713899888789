import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import {News} from "../../_models/news";
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Event} from "../../_models/event";

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  from: NgbDateStruct | null = null;
  to: NgbDateStruct | null = null;

  events : Event[] = [{
    image: 'assets/images/toDelete/6xsg8lzh.png',
    title: 'Accédez au plan interactif de Rennes...',
    description: 'Renforcement des mesures de biosécurité pour lutter contre l\'influenza aviaire dans les basses cours.',
    category: 'catégories',
    content: '<p>Hello</p>',
    from: new Date(),
  },{
    image: 'assets/images/toDelete/6xsg8lzh.png',
    title: 'Accédez au plan interactif de Rennes...',
    description: 'Renforcement des mesures de biosécurité pour lutter contre l\'influenza aviaire dans les basses cours.',
    category: 'catégories',
    content: '<p>Hello</p>',
    from: new Date(),
  },{
    image: 'assets/images/toDelete/6xsg8lzh.png',
    title: 'Accédez au plan interactif de Rennes...',
    description: 'Renforcement des mesures de biosécurité pour lutter contre l\'influenza aviaire dans les basses cours.',
    category: 'catégories',
    content: '<p>Hello</p>',
    from: new Date(),
  }];

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.commonService.changeData("Retrouvez ci-dessous toutes nos dates classées par catégories. Cliquez sur une date pour en savoir plus.");
    this.titleService.setTitle("Agenda");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Agenda'});
  }

}
