import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit {
  options: { image: string, link: string, name: string, target: string }[] = [
    {image: 'assets/images/quickly/icone-famille.svg', link: 'https://parents.logiciel-enfance.fr/brece', name: 'Portail famille', target:'_blank'},
    {image: 'assets/images/quickly/icone-annuaire-assos.svg', link: '/mes-loisirs/associations', name: 'Annuaire des assos', target:'_self'},
    {image: 'assets/images/quickly/icone-mediatheque.svg', link: '/mes-loisirs/culture/mediatheque', name: 'Médiathèque', target:'_self'},
    {image: 'assets/images/quickly/icone-bib.svg', link: 'ma-commune/publications/bib', name: 'Le BIB', target:'_self'},
    {image: 'assets/images/quickly/icone-flash-info.svg', link: 'ma-commune/publications/flash-info', name: 'Le flash info', target:'_self'},
    {image: 'assets/images/quickly/icone-intramuros.svg', link: 'https://appli-intramuros.fr/', name: 'Télécharger INTRAMUROS', target:'_blank'},
    ]
  constructor() { }

  ngOnInit(): void {
  }

}
