<section>
    <div class="container text-center text-lg-start">
        <img class="content-points" src="assets/icons/element-graphique-point.svg" />

        <div class="row content">

          <div class="col-lg-8">
            <h2 class="asso-title">{{asso.nom}}</h2>

            <img class="img-fluid"  src="{{ asso.vignette}}"  />

            <div class="contact-container mt-3">
                <div class="adress text-justify">
                    {{asso.adresse}}
                </div>
                <div class="phone text-center link">
                    <a class="link" href="tel:{{asso.phone}}">{{asso.phone}}</a>
                </div>
                <div class="web mt-3">
                    <div class="site"><a class="link" href="{{asso.site}}"> Site</a></div>
                    <div class="mail"><a class="link" href="mailto:{{asso.mail}}">Courriel</a></div>
                </div>
            </div>
            <div class="informations mt-3">
                <div class="referent mt-3">
                    <b>Personne référente: {{asso.referent}}</b>
                </div>
                <div class="autre mt-3">
                    <b>Autre contact: {{asso.autreContact}}</b>
                </div>

                <div class="mt-3">
                    <b class="mt-3">Horaires :</b>
                    <div [innerHTML]="asso.horaires">
                    </div>
                </div>

                <div class="mt-3">
                    <b class="mt-3">Informations :</b>
                    <div [innerHTML]="asso.description">
                    </div>
                </div>


            </div>
        </div>

          <div class="col-lg-4">
            <app-quick-access></app-quick-access>
          </div>

        </div>
      </div>
</section>
