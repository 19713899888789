// APP
import { AppRoutingModule } from './app-routing.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {SlickCarouselModule} from "ngx-slick-carousel";

// _DIRECTIVES
import {HoverColorInverseDirective} from './_directives/hover-color-inverse.directive';

// _HELPERS
// import { authInterceptorProviders } from './_helpers/auth.interceptor';

// _PIPES
import {SlugifyPipe} from "./_pipes/slugify";

// COMPONENTS;
import { BuilderPageComponent } from './components/builder-page/builder-page.component';

// PAGES
import { HomeComponent } from './pages/home/home.component';

// LAYOUT
import { HomeComponent as HomeLayoutComponent } from './_layout/home/home.component';
import { WebsiteComponent as WebsiteLayoutComponent } from './_layout/website/website.component';
import {FooterComponent} from "./_layout/_includes/footer/footer.component";
import {ConseilComponent} from "./pages/conseil/conseil.component";
import {NgDynamicBreadcrumbModule, NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import {QuickAccessComponent} from "./components/quick-access/quick-access.component";
import {NewsComponent} from "./pages/news/news.component";
import {NewsDescribeComponent} from "./pages/news-describe/news-describe.component";
import {AgendaComponent} from "./pages/agenda/agenda.component";
import {AgendaDescribeComponent} from "./pages/agenda-describe/agenda-describe.component";
import {CcasComponent} from "./pages/ccas/ccas.component";
import {DailyComponent} from "./pages/daily/daily.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {SearchComponent} from "./pages/search/search.component";
import { ElusComponent } from './pages/ma-commune/conseil/elus/elus.component';
import { FlashComponent } from './pages/ma-commune/publications/flash/flash.component';
import { BibComponent } from './pages/ma-commune/publications/bib/bib.component';
import { PersonnelsComponent } from './pages/ma-commune/services/personnels/personnels.component';
import { EquipementsComponent } from './pages/ma-commune/services/equipements/equipements.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { NgxMasonryModule } from 'ngx-masonry';

registerLocaleData(localeFr, 'fr');
import {LOCALE_ID} from '@angular/core';
import { MesLoisirsComponent } from './pages/mes-loisirs/mes-loisirs.component';
import { AssociationsComponent } from './pages/mes-loisirs/associations/associations.component';
import { CultureComponent } from './pages/mes-loisirs/culture/culture.component';
import { AidesComponent } from './pages/mes-loisirs/aides/aides.component';
import { EvenementsComponent } from './pages/mes-loisirs/evenements/evenements.component';
import { MonQuotidienComponent } from './pages/mon-quotidien/mon-quotidien.component';
import { ActionSocialeEtSanteComponent } from './pages/mon-quotidien/action-sociale-et-sante/action-sociale-et-sante.component';
import { EnvironnementComponent } from './pages/mon-quotidien/environnement/environnement.component';
import { SeDeplacerComponent } from './pages/mon-quotidien/se-deplacer/se-deplacer.component';
import { CommercesComponent } from './pages/mon-quotidien/commerces/commerces.component';
import { UrbanismeComponent } from './pages/mon-quotidien/urbanisme/urbanisme.component';
import { ReglementationComponent } from './pages/mon-quotidien/reglementation/reglementation.component';
import { MaCommuneComponent } from './pages/ma-commune/ma-commune.component';
import { PublicationsComponent } from './pages/ma-commune/publications/publications.component';
import { ServicesComponent } from './pages/ma-commune/services/services.component';
import { IntercommunaliteComponent } from './pages/ma-commune/intercommunalite/intercommunalite.component';
import { TerritoireComponent } from './pages/ma-commune/territoire/territoire.component';
import { PageIntermediaireComponent } from './pages/page-intermediaire/page-intermediaire.component';
import { PageBuilderComponent, SafeHtmlPipe } from './pages/page-builder/page-builder.component';
import { ArretesComponent } from './pages/ma-commune/publications/arretes/arretes.component';
import { PropositionFormComponent } from './proposition-form/proposition-form.component';
import { MentionsLegalesComponent } from './pages/mentions-legales/mentions-legales.component';
import { PolitiqueConfidentialiteComponent } from './pages/politique-confidentialite/politique-confidentialite.component';
import { CrConseilComponent } from './pages/ma-commune/conseil/cr-conseil/cr-conseil.component';
import { EnfanceEtJeunesseComponent } from './pages/enfance-et-jeunesse/enfance-et-jeunesse.component';
import { AnnuaireComponent } from './pages/mes-loisirs/associations/annuaire/annuaire.component';
import { DetailsAssociationComponent } from './pages/mes-loisirs/associations/details-association/details-association.component';
import { GallerieComponent } from './gallerie/gallerie.component';
import { AgendaDescribeIntraMurosComponent } from './pages/agenda-describe-intra-muros/agenda-describe-intra-muros.component';
import { NewsImDescribeComponent } from './pages/news-im-describe/news-im-describe.component';
import { NgAisModule } from 'angular-instantsearch';
import { NosAinesRacontentComponent } from './pages/ma-commune/nos-aines-racontent/nos-aines-racontent.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeComponent,
    HomeLayoutComponent,
    WebsiteLayoutComponent,
    HoverColorInverseDirective,
    SlugifyPipe,
    FooterComponent,
    ConseilComponent,
    QuickAccessComponent,
    NewsComponent,
    NewsDescribeComponent,
    AgendaComponent,
    AgendaDescribeComponent,
    CcasComponent,
    NotFoundComponent,
    ContactComponent,
    DailyComponent,
    SearchComponent,
    ElusComponent,
    FlashComponent,
    BibComponent,
    PersonnelsComponent,
    EquipementsComponent,
    MesLoisirsComponent,
    AssociationsComponent,
    CultureComponent,
    AidesComponent,
    EvenementsComponent,
    MonQuotidienComponent,
    ActionSocialeEtSanteComponent,
    EnvironnementComponent,
    SeDeplacerComponent,
    CommercesComponent,
    UrbanismeComponent,
    ReglementationComponent,
    MaCommuneComponent,
    PublicationsComponent,
    ServicesComponent,
    IntercommunaliteComponent,
    TerritoireComponent,
    PageIntermediaireComponent,
    PageBuilderComponent,
    ArretesComponent,
    SafeHtmlPipe,
    PropositionFormComponent,
    MentionsLegalesComponent,
    PolitiqueConfidentialiteComponent,
    CrConseilComponent,
    EnfanceEtJeunesseComponent,
    AnnuaireComponent,
    DetailsAssociationComponent,
    GallerieComponent,
    AgendaDescribeIntraMurosComponent,
    NewsImDescribeComponent,
    NosAinesRacontentComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    CommonModule,
    SlickCarouselModule,
    NgDynamicBreadcrumbModule,
    NgbModule,
    ReactiveFormsModule,
    NgxMasonryModule,
    NgAisModule.forRoot()
  ],
  providers: [
    // authInterceptorProviders
    {provide: LOCALE_ID, useValue: 'fr' }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(public ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {}

}
