import { Component, OnInit } from '@angular/core';
import { AssociationsService } from 'src/app/_services/associations.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss']
})
export class AssociationsComponent implements OnInit {
  assos: any;
  constructor(private association: AssociationsService) { }

  ngOnInit(): void {
    this.getAssos();
  }

  getAssos() {
    this.association.getAssos().subscribe(data => {
      this.assos = data.data;
    })
  }
}
