<section class="actualite-container mt-5">
    <div class="container">
      <div class="builder-container">
        <h2 class="title">{{event.title}}</h2>
        <div class="tags"> <p *ngFor="let tag of event.category">{{ tag.name }} &nbsp;</p> | {{ event.start_date|date:'medium' }}</div>

        <div class="diaporama">
          <ngx-slick-carousel class="carousel intro-carrousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of sliders" class="slide">
              <div *ngIf="slide.startsWith('https://www.youtube.com')">
                <iframe width="100%" height="315" [src]="sanitized(slide)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
              <div *ngIf="!slide.startsWith('https://www.youtube.com')">
                <img class="img-slide" src="{{slide}}">
              </div>
            </div>
          </ngx-slick-carousel>
        </div>

        <div class="description" [innerHTML]="event.description"></div>

        <div class="liens">
          <div class="downloads" *ngIf="event.attachment">
            <p>Téléchargement</p>
                <a href="{{event.attachment}}">{{event.attachment}}</a>
          </div>

          <div class="url" *ngIf="url.length > 0">
              <p>En savoir plus :</p>
              <div *ngFor="let link of url">
                  <a href="{{link}}">{{link}}</a>
              </div>
          </div>
        </div>
      </div>

      <div class="form-container">
        <div class="col-lg-4 mt-lg-0 mt-3">
          <div class="filter p-5 ">
            <p class="py-2 pt-4">Filtrer les résultats par :</p>
            <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label class="mb-3">Catégorie :</label>
                <div>
                  <select class="d-block w-100" formControlName="categorie">
                    <option value="" disabled selected>Selectionnez une catégorie</option>
                    <option *ngFor="let tag of tags" value="{{tag.id}}">{{tag.nom}}</option>
                  </select>
                </div>
                <div class="form-group row my-3">
                  <label for="from" class="col-sm-2 col-form-label">Du :</label>
                  <div class="col-sm-10">
                    <input type="date" readonly class="form-control-plaintext" id="from"
                           formControlName="dateDebut"
                           [(ngModel)]="from" ngbDatepicker #d="ngbDatepicker"
                           (click)="d.toggle()"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label for="to" class="col-sm-2 col-form-label">Au :</label>
                  <div class="col-sm-10">
                    <input type="date" readonly class="form-control-plaintext" id="to"
                           [(ngModel)]="to" ngbDatepicker #f="ngbDatepicker"
                           (click)="f.toggle()"
                           formControlName="dateFin"
                    >
                  </div>
                </div>
                <div>
                  <button class="btn btn-dark d-block w-100 my-4">Valider</button>
                  <button (click)="openPopUpPage('evenement')" class="btn btn-outline-dark d-block w-100">PROPOSER UN ÉVÈNEMENT</button>
                </div>
              </div>
            </form>
          </div>
          <img class="img-fluid mt-5 d-none d-lg-block" src="assets/icons/element-graphique-point.svg" />
        </div>
      </div>

    </div>
  </section>
