import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

const API = `${environment.api}/api/inscriptions/add`;

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  add(info: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    var tabData = {};
    tabData = {
      'nom': info.nom,
      'prenom': info.prenom,
      'mail': info.mail
    };
    const body = tabData;

    return this.http.post(API, body, httpOptions);
  }
}
