import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {Observable} from 'rxjs';


const EVENEMENT = "https://api.appli-intramuros.com/events/?city-id=2890&agglo-id=316";
const NEWS = "https://api.appli-intramuros.com/news/?city-id=2890&agglo-id=316";
const ONENEWS = "https://api.appli-intramuros.com/news/";
@Injectable({
  providedIn: 'root'
})
export class IntraMurosService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getNews(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    return this.http.get(NEWS);
  }

  getNew(id: number): Observable<any> {
    return this.http.get(ONENEWS + id);
  }

  getEvents(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    return this.http.get(EVENEMENT);
  }

  getEvent(id: number): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    return this.http.get('https://api.appli-intramuros.com/events/' + id);
  }

  getCategory(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };

    return this.http.get('https://api.appli-intramuros.com/categories?type=event&format=json');
  }
}
