import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

const API = `${environment.api}/api/menus`;
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getMenus(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };        
    return this.http.get(API, httpOptions);
  }

  getChildren(rubrique: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };        
    return this.http.get(API + '/' + rubrique, httpOptions);
  }
}
