import { Component, OnInit, Pipe, PipeTransform, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { CommonService } from 'src/app/_services/common.service';
import { PageService } from 'src/app/_services/page.service';

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageBuilderComponent implements OnInit {
  menu: any;
  section: any;
  rubrique: any;
  title: any;
  page: any;
  style: any;
  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private pageService: PageService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe( params => 
      {        
         this.title = params["rubrique"];
         this.rubrique = params["rubrique"];
         console.log(params['rubrique']);
         console.log(params['item']);
         
        if (params['rubrique'] === 'petite-enfance' || params['rubrique'] === 'enfance' || params['rubrique'] === 'jeunesse') {
          this.title = params['item'];
          this.menu = "Mon quotidien";
        }
        if (params['rubrique'] === 'nos-aines-racontent') {          
          this.title = params['item'];
          this.menu = "Ma Commune";
        }
         switch (params["menu"]) {
          case 'ma-commune':
            this.menu = 'Ma Commune';
            break;
          case 'mon-quotidien':
            this.menu = 'Mon quotidien';
            break;
          case 'mes-loisirs':
            this.menu = 'Mes loisirs';         
            break;
          case 'mes-demarches':
            this.menu = 'Mes Démarches';
            break;
         }
         this.section = params["section"];
         this.getPage();
      });
    //this.title = this.route.snapshot.paramMap.get('rubrique');
    
  }

  getPage() {
    this.pageService.getPageByTitle(this.title).subscribe(data => {
      this.commonService.changeData("");
      this.titleService.setTitle(data.item);
      console.log(data);
      
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: this.menu, customSection: data.parent, customRubrique: data.item});
      this.page = data;
      this.style = '<style>'+ this.page.pageStyle +'</style>';
    })
  }

}
