import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { MenuService } from 'src/app/_services/menu.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentChecked {

  checked: boolean = false;
  menu: any;
  actualites: any;
  evennements: any;
  bib: any;
  flash: any;
  constructor(private changeDetector: ChangeDetectorRef, private menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.getMenus().subscribe(data => {
      this.menu = data[0];            
    });
  }

  showToggle() {
    this.checked = !this.checked
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

}
