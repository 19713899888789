import { Component, OnInit } from '@angular/core';
import { EquipementService } from 'src/app/_services/equipement.service';

@Component({
  selector: 'app-equipements',
  templateUrl: './equipements.component.html',
  styleUrls: ['./equipements.component.scss']
})
export class EquipementsComponent implements OnInit {
  equipements: any
  constructor(private equipementService: EquipementService) { }

  ngOnInit(): void {
    this.getEquipements();
  }

  getEquipements(){
    this.equipementService.getAll().subscribe(data => {
      this.equipements = data.data;
    })
  }
}
