<footer>
  <div class="container-fluid">
    <div class="row-bootstrap">
      <div class="col-lg-4 left-content">
        <div class="row-bootstrap">
          <div class="col text-lg-end text-center">
            <img src="/assets/images/logo.png"   class="img-fluid">
          </div>
          <div class="col-lg text-center text-lg-start mt-3 mt-lg-0">
            <p class="mb-1" ><strong>Mairie de Brécé</strong></p>
            <p class="m-0"><small>6 rue de Rennes</small></p>
            <p class="m-0"><small>35 530 Brécé</small></p>
            <p class="m-0"><small>Tél: <a class="phone-link" href="tel:+33299001009">02 99 00 10 09</a></small></p>
            <button class="btn btn-dark mt-4">
              <span class="fa fa-envelope"></span>
              <a class="link" href="/contact"> NOUS ÉCRIRE</a>
            </button>
          </div>
        </div>
      </div>
      <div class="col right-content text-lg-start text-center">
        <div class="row-bootstrap">
          <div class="col-lg-6">
            <h3><strong>Horaires d'ouverture</strong></h3>
            <p  class="m-0"><small>Le Lundi : de 8h30 à 12h00 et de 13h30 à 17h00</small></p>
            <p  class="m-0"><small>Le Mardi : de 8h30 à 12h00</small></p>
            <p  class="m-0"><small>Le Jeudi : de 8h30 à 12h00</small></p>
            <p  class="m-0"><small>Le mercredi et Vendredi : de 8h30 à 12h00 et de 13h30 à 17h00</small></p>
            <p  class="m-0"><small>Le Samedi : de 10h00 à 12h00</small></p>
            <div class="d-flex mt-3">
              <a href="https://metropole.rennes.fr/" target="_blank"><img src="assets/icons/logo-rennes-metropole.png"   class="img-fluid ms-2"></a>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <h3>Lettre d'information</h3>
            <p><small>Vous souhaitez recevoir les dernières informations de la Ville ? Inscrivez-vous aux newsletters</small></p>
            <a href="#inscriptions">
              <button class="btn btn-dark mt-3">S'INSCRIRE</button>
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <div class="text-lg-end text-footer text-center">
            <ul>
              <li>
                <a class="link" href="/">Accueil</a>
              </li>
              <!-- <li>
                <a>Flux RSS</a>
              </li> -->
              <li>
                <a>Plan du site</a>
              </li>
              <li>
                <a class="link" routerLink="mentions-legales">Mentions légales</a>
              </li>
              <li>
                <a class="link" routerLink="confidentialite">Politique de confidentialité</a>
              </li>
              <li>
                <a class="link" href="https://www.agence-11h10.fr/" target="_blank">Agence 11h10</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
