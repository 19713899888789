import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intercommunalite',
  templateUrl: './intercommunalite.component.html',
  styleUrls: ['./intercommunalite.component.scss']
})
export class IntercommunaliteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
