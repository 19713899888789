import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter, HostListener,
  Input,
  OnInit, Output
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { ResearchService } from 'src/app/_services/research.service';
import algoliasearch from 'algoliasearch';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const searchClient = algoliasearch(
  'F5TWA2S8OH',
  '52ff614018a16a023549c73b7beb5a6b'
);
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class SearchComponent implements OnInit, AfterContentChecked {
  faArrow = faArrowRight;
  indexActu = 'dev_actualites';
  indexElus = 'dev_elus';
  indexEquipements = 'dev_equipements';
  indexBib = 'dev_bib';
  indexInfo = 'dev_informations';
  indexAssos = 'dev_associations';
  indexPersonnels = 'dev_personnels';
  indexEvents = 'dev_evenements';
  indexFlash = 'dev_flash';
  indexAides = 'dev_aides';
  // allIndexes = [this.indexActu, this.indexElus];

  @Input() show: boolean = false;
  @Output() showToggle: EventEmitter<any> = new EventEmitter();
  resultats: any = [];
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  search(event: KeyboardEvent){
    this.resultats.splice(0, this.resultats.length);
    let value = (<HTMLInputElement>event.target).value
    if (value.length >= 3) {
      searchClient.search([
        {indexName: this.indexActu, query: value},
        {indexName: this.indexElus, query: value},
        {indexName: this.indexEquipements, query: value},
        {indexName: this.indexBib, query: value},
        {indexName: this.indexInfo, query: value},
        {indexName: this.indexAssos, query: value},
        {indexName: this.indexPersonnels, query: value},
        {indexName: this.indexEvents, query: value},
        {indexName: this.indexFlash, query: value},
        {indexName: this.indexAides, query: value},
      ]).then(({ results }) => {
        results.forEach(element => {
          element['hits'].forEach(item => {
            const objectExist = this.resultats.some(resultItem => resultItem.objectID === item['objectID']);
            if (!objectExist) {
              this.resultats.push(item);
            }
          });
        });
      })
    }
  }

  hide(): void {
    this.showToggle.emit();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let value = (<HTMLInputElement>event.target).value
    if (value) {
      if (value.length > 2) {
        console.log(value);
      }
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
