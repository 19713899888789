import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-se-deplacer',
  templateUrl: './se-deplacer.component.html',
  styleUrls: ['./se-deplacer.component.scss']
})
export class SeDeplacerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
