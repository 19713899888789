<section id="search" *ngIf="show" [@slideInOut]>
  <div class="header">
    <nav class="navbar navbar-expand-lg sticky-top ">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between w-100">
          <a href="/" class="navbar-brand">
            <img src="/assets/images/logo.png" height="70" alt="CoolBrand" class="d-none d-lg-block">
            <img src="/assets/images/logo.png" height="50" alt="CoolBrand" class="d-block d-lg-none">
          </a>
          <form name="search.form" class="w-50">
            <input class="form-control w-100"  type="text" placeholder="Votre recherche" (keypress)="search($event)">
          </form>

          <div>
            <a href="/contact"><span class="fa fa-envelope text-black"></span></a>
            <a href="tel:+330299001009"><span class="fa fa-phone mx-4 text-black"></span></a>
            <span class="fa fa-remove  text-black" (click)="hide()"></span>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div class="body" [ngStyle]="{'background-color': resultats === null ? ' rgba(0, 0, 0, 0.7)' : 'white'}">
    <div class="container">

      <ul class="p-0 m-0">
        <li  *ngFor="let item of resultats" class="py-3">
          <a *ngIf="item?.titre" class="text-decoration-none text-danger" href="{{ item.url}}"> <i class="fa-solid fa-arrow-right-to-bracket"></i> {{item.titre}}</a>
          <a *ngIf="item?.nom" class="text-decoration-none text-danger" href="{{ item.url}}"> <i class="fa-solid fa-arrow-right-to-bracket"></i> {{item.nom}}</a>
        </li>
      </ul>
    </div>

  </div>

</section>
