<section class="mt-5">
    <div class="container text-center text-lg-start">
      <img class="content-points" src="assets/icons/element-graphique-point.svg" />
      <div class="row content">
        <div class="col-lg-8">
          <div class="row-bootstrap">
            <div class="col-lg-4 partner my-3"  *ngFor="let partner of flash">
                <img class="img-fluid"  src="{{ partner.vignette}}"  />
                <div class="mt-3">
                  <p class="text-dark mb-2" style="min-height: 50px;"><strong>{{ partner.nom }}</strong></p>
                  <div class="divider mb-2"></div><br>
                  <a href="{{partner.fichier}}" target="_blank">
                    <button class="btn btn-dark">TELECHARGER</button>
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <app-quick-access></app-quick-access>
        </div>
      </div>
    </div>

  </section>
