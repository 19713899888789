import { Component, OnInit, Input } from '@angular/core';
import { GallerieService } from '../_services/gallerie.service';

@Component({
  selector: 'app-gallerie',
  templateUrl: './gallerie.component.html',
  styleUrls: ['./gallerie.component.scss']
})
export class GallerieComponent implements OnInit {
  images: any;
  @Input() public img: any;
  @Input() public modal: any;
  constructor(private gallerieService: GallerieService) { }

  ngOnInit(): void {
    this.getGallerie();
  }

  getGallerie() {
    this.gallerieService.getGalleries().subscribe(data => {
      this.images = data[0].images;
    })
  }

  setImg(image: any) {
    this.img = image;
  }
}
