import { Component, OnInit } from '@angular/core';
import { FlashService } from 'src/app/_services/flash.service';
import {Title} from "@angular/platform-browser";
import { CommonService } from 'src/app/_services/common.service';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';

@Component({
  selector: 'app-flash',
  templateUrl: './flash.component.html',
  styleUrls: ['./flash.component.scss']
})
export class FlashComponent implements OnInit {

  flash: any;
  constructor(private flashService: FlashService,
    private commonService: CommonService,
    private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.getFlash();
  }

  getFlash() {
    this.flashService.getAllFlash().subscribe(data => {
      this.flash = data.data;
      this.commonService.changeData("");
      this.titleService.setTitle("Flash-info");
      this.flash.sort((a, b) => {return new Date(b.dateCreation.date).getTime() - new Date(a.dateCreation.date).getTime()});
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customMenu: 'Ma Commune', customSection: 'Publications',customTitle: 'Flash-info'});
    })
  }
}
