import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import {NgbDateStruct, NgbModal, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/_services/common.service';
import { EvenementService } from 'src/app/_services/evenement.service';
import { TagService } from 'src/app/_services/tag.service';
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { IntraMurosService } from 'src/app/_services/intra-muros.service';

@Component({
  selector: 'app-evenements',
  templateUrl: './evenements.component.html',
  styleUrls: ['./evenements.component.scss']
})
export class EvenementsComponent implements OnInit {

  from: NgbDateStruct | null = null;
  to: NgbDateStruct | null = null;
  eventsFiltered: any;
  events : any;
  tags : any;
  eventForm: any;

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private eventService: EvenementService, private tagService: TagService, private modalService: NgbModal, private route: ActivatedRoute,
    private formBuilder: FormBuilder, private ngbDateParser: NgbDateParserFormatter, private intraMurosService: IntraMurosService) { }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      if (params.categorie !== undefined || params.dateDebut !== undefined || params.dateFin !== undefined) {
        var ngbDateDebut = {
          day: params?.jourDebut,
          month: params?.moisDebut,
          year: params?.anneeDebut,
        }
        var ngbDateFin = {
          day: params?.jourFin,
          month: params?.moisFin,
          year: params?.anneeFin,
        }

        let info = {
          'categorie' : params.categorie,
          'dateDebut': ngbDateDebut,
          'dateFin': ngbDateFin,
        };
        this.eventService.getEventsByCategories(info).subscribe(data => {
          this.events = data;
        });
      } else {
        this.getEvents();
      }
    });

    this.eventForm = this.formBuilder.group({
      categorie: new FormControl(''),
      dateDebut: new FormControl(''),
      dateFin: new FormControl(''),
    })

    this.commonService.changeData("Retrouvez ci-dessous toutes nos dates classées par catégories. Cliquez sur une date pour en savoir plus.");
    this.titleService.setTitle("Evenements");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customSection: 'Mes loisirs',customTitle: 'Evenements'});
    this.getTags();
  }

  getEvents() {
    this.eventService.getEvents().subscribe(data => {
      this.events = data;
      this.getIMEvents();
      this.events.sort((a, b) => {return new Date(a.dateDebut.date).getTime() - new Date(b.dateDebut.date).getTime()});
      this.eventsFiltered = this.events;
    });
  }
  async getIMEvents() {
    await this.intraMurosService.getEvents().subscribe(data => {
      data.forEach(async element => {
        if (element.category == null){
          this.events.push({
            id: element.id,
            vignette: element.image,
            titre: element.title,
            dateDebut: {date: element.start_date, timezone_type: 3, timezone: 'Europe/Paris'},
            dateFin: {date: element.end_date, timezone_type: 3, timezone: 'Europe/Paris'},
            description: element.description,
          });
        }else{
          this.events.push({
            id: element.id,
            vignette: element.image,
            titre: element.title,
            dateDebut: {date: element.start_date, timezone_type: 3, timezone: 'Europe/Paris'},
            dateFin: {date: element.end_date, timezone_type: 3, timezone: 'Europe/Paris'},
            description: element.description,
            tags: [{
              id: element.category,
              nom: await this.getCategoryName(element.category)
            }]
          });
        }
      });
      this.events.sort((a, b) => {return new Date(a.dateDebut.date).getTime() - new Date(b.dateDebut.date).getTime()});
    });
  }

  async getCategoryName(id: number): Promise<any> {
    let categories: any;
    let categorie: any;
    return new Promise<any>((resolve, reject) => {
      this.intraMurosService.getCategory().subscribe(data => {
        categories = data;
        categorie = categories.find((el => el.id === id));
        return resolve(categorie.name);
      });

    })
  }

  getTags() {
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
      this.getIMTags();
    })
  }

  async getIMTags() {
    await this.intraMurosService.getCategory().subscribe(data => {

      data.forEach(element => {
        this.tags.push({
          id: element.id,
          nom: element.name
        })
      });

    })
  }

  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl', centered:true});
    modalCreate.componentInstance.type = type;
  }

  onSubmit(): void {
    let categorie = this.eventForm.get('categorie').value;
    let startDate = this.eventForm.get('dateDebut').value;
    startDate = this.ngbDateParser.format(startDate);
    let endDate = this.eventForm.get('dateFin').value;
    endDate = this.ngbDateParser.format(endDate);
    let filtered = this.events;

    if(categorie !== "") {
      filtered = filtered.filter(el => el.tags.find(elem => elem.id == categorie));
    }

    if (startDate !== "") {
      //this.events.filter(el => el.date.find(elem => elem.dateDebut.date > startDate));
      filtered = filtered.filter(el => el.dateDebut.date >= startDate);
    }

    if (endDate !== "") {
      filtered = filtered.filter(el => el.dateFin.date <= endDate);
    }

    this.eventsFiltered = filtered;
    console.log(this.eventsFiltered);


  }
}
