<ngx-spinner name="spinnerContact" bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-pulse"><p style="color: white" > Chargement... </p></ngx-spinner>
<div class="container">
  <div>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="mt-4">
      <div class="row-bootstrap">
        <div class="form-group col">
          <label for="lastname">Votre nom *</label>
          <input type="text" class="form-control" id="lastname" placeholder="ex. Dupont" formControlName="nom">
        </div>
        <div class="form-group col">
          <label for="firstname">Votre prénom *</label>
          <input type="text" class="form-control" id="firstname"   placeholder="ex. Martin" formControlName="prenom">
        </div>
      </div>
      <div class="row my-4">
        <div class="form-group col">
          <label for="address">Votre adresse *</label>
          <input type="text" class="form-control" id="address" placeholder="ex. rue de martin" formControlName="adresse">
        </div>
        <div class="form-group col">
          <label for="zipcode">Votre code postal *</label>
          <input type="text" class="form-control" id="zipcode"   placeholder="ex. 35530" formControlName="cp">
        </div>
      </div>

      <div class="row-bootstrap">
        <div class="form-group col">
          <label for="city">Votre ville *</label>
          <input type="text" class="form-control" id="city" placeholder="ex. Brécé" formControlName="ville">
        </div>
        <div class="form-group col">
          <label for="email">Votre email *</label>
          <input type="email" class="form-control" id="email"   placeholder="ex. monemail@mail.com" formControlName="mail">
        </div>
        <div class="form-group col">
          <label for="tel">Votre téléphone *</label>
          <input type="tel" class="form-control" id="tel"   placeholder="ex. 0123456789" formControlName="tel">
        </div>
      </div>
      <div class="form-group col mt-4">
        <label for="message">Votre message </label>
        <textarea class="form-control"  id="message" rows="5" formControlName="message"></textarea>
      </div>
      <div class="form-check my-4">
        <input type="checkbox" class="form-check-input" id="check">
        <label class="form-check-label" for="check"> En soumettant ce formulaire, j’accepte que les informations saisies dans ce formulaire soient utilisées, exploitées, traitées pour permettre de me recontacter, pour m’envoyer des informations, dans le cadre de la relation commerciale qui découle de cette demande.
          <a href="#" class="text-danger text-decoration-none">* En savoir plus.</a></label>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-dark">VALIDER</button>
      </div>
    </form>
  </div>
</div>
