import { Component, OnInit } from '@angular/core';
import {News} from "../../_models/news";
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { ActualiteService } from 'src/app/_services/actualite.service';
import { TagService } from 'src/app/_services/tag.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntraMurosService } from 'src/app/_services/intra-muros.service';
import { formatDate, getLocaleDateTimeFormat } from '@angular/common';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  actualites: any = [];
  actualitesIM: any;
  actualitesDB: any;
  tags: any;
  categorieForm: any;

  constructor(private commonService: CommonService, private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService, private actuService: ActualiteService,
    private tagService: TagService, private modalService: NgbModal, private formbuilder: FormBuilder, private route: ActivatedRoute,
    private intraMurosService: IntraMurosService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if (params.categorie !== undefined) {
          this.actuService.getActualitesResearch(params.categorie).subscribe(data => {
            this.actualites = data;
          });
        } else {
          this.getActualites();
        }
      });
    this.categorieForm = this.formbuilder.group({
      categorie: new FormControl('')
    });
    this.commonService.changeData("Retrouvez ci-dessous toutes nos actualités, classées par catégories. Cliquez sur une actualité pour en savoir plus..");
    this.titleService.setTitle("Actualités");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Actualités'});
    this.getTags();
  }

  classifyActu(actu: any) {
    actu.forEach(element => {
      if (element?.slug) {
        this.actualites.push(element);
      } else {
        let dateFormat = formatDate(new Date(element.published_at), 'yyyy-MM-dd HH:mm:ss.SSSSSS', 'fr', 'Europe/Paris');
        let dateObj = {
          date: dateFormat,
          timezone: "Europe/Paris",
          timezone_type: 3
        }

        var actu = {
          dateCreation: dateObj,
          dateDebut: dateObj,
          dateFin: null,
          display: true,
          id: element.id,
          slug: this.slugify(element.title),
          tags: null,
          texte_vignette: null,
          titre: element.title,
          urlImage: element.image,
          intra: true
        };
        this.actualites.push(actu);
        this.actualites.sort((a, b) => {return new Date(b.dateDebut.date).getTime() - new Date(a.dateDebut.date).getTime()});
      }
    });
  }

  getActualites() {
    this.actuService.getActualites().subscribe(data => {
      this.actualites = data;
      this.getActuIM();
    });
  }

  getActuIM() {
    this.intraMurosService.getNews().subscribe(data => {
      this.actualitesIM = data;
      this.classifyActu(this.actualitesIM);
    });
  }

  getTags(){
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
    })
  }

  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl', centered:true});
    modalCreate.componentInstance.type = type;
  }

  slugify(str: string) {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaeeeeiiiioooouuuunc------";

    return str.split('').map((letter, i) => {
      let index = from.indexOf(letter);
      if (index !== -1) {
        return to[index];
      }
      return letter;
    }).join('').replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');
  }

  onSubmit(): void {
    console.log(this.categorieForm.value['categorie']);

    this.actuService.getActualitesResearch(this.categorieForm.value['categorie']).subscribe(data => {
      this.actualites = data;
    });
  }
}
