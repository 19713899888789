import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

const API = `${environment.api}/`;

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  sendProposition(type: string, info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    let object = ''
    switch (type) {
      case 'actualite':
        object = 'Nouvelle proposition d\'actualité';
        break;
      case 'evenement':
        object = 'Nouvelle proposition d\'évènement';
        break;
    }
    var tabData = {};
    tabData = {
      'nom': info.nom,
      'prenom': info.prenom,
      'email': info.mail,
      'phone': info.tel,
      'message': info.description,
      'object': object,

    };
    const body = tabData;

    return this.http.post(API + 'api/proposition', body, httpOptions);
  }

  sendMail(type: string, info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };
    let object = ''

    var tabData = {};
    tabData = {
      'nom': info.nom,
      'prenom': info.prenom,
      'email': info.mail,
      'phone': info.tel,
      'adresse': info.adresse,
      'cp': info.cp,
      'ville': info.ville,
      'message': info.message,


    };
    const body = tabData;

    return this.http.post(API + 'api/contact', body, httpOptions);
  }
}
