<section class="mt-5">
    <div class="politique-container">
        <div class="titre">
            <h1 class="recommandations-titre">Recommandations CNIL<br><br></h1>
            <ol type="I">
                <li>
                    <h4>Qu'est ce qu'un cookie</h4>
                    <p>
                        Un cookies est un fichier texte sauvegardé sur votre ordinateur ou votre appareil mobile puis récupéré lors de vos visites ultérieures.
                    </p>
                </li>
                <li>
                    <h4>Les obligations légales</h4>
                    <p>
                        L'internaute qui se rend sur le site éditeur se doit d' être informé de la finalités des cookies, 
                        l' éditeur doit obtenir son consentement et doit fournir aux internautes un moyen de les refuser. 
                        La durée de validité de ce consentement est de 13 mois. L' obligation du recueil du consentement 
                        s'impose aux responsables des sites internet, aux éditeurs d'applications, aux régies publicitaires, 
                        aux éditeurs de solutions de mesures d'audience ainsi qu'aux réseaux sociaux. Certains cookies nécessitent 
                        une information préalable et une demande de consentement au prêt des internautes, tels que les cookies liés 
                        aux opérations relatives à la publicité et les cookies de mesures d'audience.                    
                    </p>
                </li>
                <li>
                    <h4>L'affichage d'un bandeau d'information</h4>
                    <p>
                        Ce bandeau informe les internautes sur les finalités précises des cookies utilisés, la possibilité de s'opposer 
                        à ces cookies et de changer les paramètres en mettant un lien présent sur le bandeau. Le lien «en savoir plus» 
                        permet à l'utilisateur de paramétrer les cookies, les accepter ou les refuser. Ce lien doit informer de 
                        manière claire et concise l'usager ce qu'est un cookie.                    
                    </p>
                </li>
                <li>
                    <h4>Les solutions Webanalytics</h4>
                    <p>
                        Piwik et Google Analytics sont des outils de mesures d'audience qui permettent d'obtenir des informations sur 
                        la navigation des visiteurs. Ce sont des outils dispensés de consentement mais devant respecter certaines normes. 
                        Le mécanisme d'opposition aux cookies doit être accessible simplement, les cookies déposés servent uniquement a la 
                        production de statistiques anonymes, les cookies ne doivent pas permettre de suivre la navigation de l'internaute 
                        sur d'autres sites, les cookies permettant la traçabilité des internautes et les adresse IP ne doivent pas être 
                        conservés au-delà de 13 mois à compter de la dernier visite.
                    </p>
                </li>
                <li>
                    <h4>Les boutons sociaux</h4>
                    <p>
                        Share Privacy est un outil qui permet d'intégrer les boutons des principales plates-formes sociales sans envoyer de cookies 
                        avant d'obtenir un consentement préalable de l'utilisateur. Les boutons sociaux sont des plug-ins 
                        sociaux qui permettent aux concepteurs de site d'ajouter facilement à leurs pages web des fonctionnalités 
                        permettant de faciliter le partage du contenu de leurs sites sur les différentes plates-formes sociales. 
                        Ce sont des outils qui nécessitent un consentement préalable de l'internaute.
                    </p>
                </li>
            </ol>
        </div>
        

        <div class="titre mt-5">
            <h1 class="recommandations-titre">Collecte des données personnelles (RGPD)<br><br></h1>
            <b>
                www.mairie-brece.fr attache une grande importance à la protection de vos données personnelles. 
                Afin de suivre cette éthique, nous vous proposons de prendre connaissance de notre charte qui complète les conditions 
                d'utilisation actuelle du Site ainsi que nos mentions légales. Si vous êtes en désaccord avec ces termes, vous êtes 
                libre de ne pas naviguer sur www.mairie-brece.fr et de ne nous fournir aucune donnée personnelle. <br><br>
            </b>
            <ol type="I">
                <li>
                    <h4>A quoi la présente politique de confidentialité et de protection des données personnelles s'applique-t-elle ?</h4>
                    <p>
                        La présente politique de protection des données personnelles s'applique â l'ensemble du site internet, des applications et supports digitaux, ainsi qu'â toute autre initiative en ligne type jeux-concours ou tirages au sort appartenant â la commune de Brécé et procédant â la collecte de données personnelles. La présente politique ne s'applique aucunement aux sites internet de tiers utilisant notre nom de marque â des fins de promotion, y compris â ceux éventuellement mentionnés sur nos Supports par l'intermédiaire d'un lien internet comme peuvent l'être des sites "Partenaires".
                    </p>
                </li>
                <li>
                    <h4>Quelles sont les données collectées ?</h4>
                    <p>
                        Avec votre consentement préalable, lorsque vous utilisez notre Supports nous sommes susceptibles de collecter et de traiter tout ou partie des données suivantes :
                    </p>    
                        <ul>
                            <li>Prénom et nom de famille ;</li>
                            <li> Adresse de courrier électronique ;</li>
                        </ul>
                    
                </li>
                <li>
                    <h4>Collectons-nous votre adresse ip et vos témoins de connexion (cookies)?</h4>
                    <p>
                        Vous pouvez configurer votre navigateur et/ou votre terminal afin d'accepter ou refuser les cookies. Nous tenons par ailleurs â vous rappeler que le paramétrage de l'utilisation des cookies peut limiter votre accès â certains services ou fonctionnalités du Site. Vous pouvez, selon vos habitudes de navigation, soit enregistrer tous les cookies émis sur notre site Internet, soit les refuser systématiquement, ou encore décider au cas par cas. Vous trouverez des informations sur le paramétrage des principaux navigateurs en vous rendant sur leurs pages d'aide ou sur leurs menus situés généralement en haut â gauche de votre écran.                    
                    </p>
                </li>
                <li>
                    <h4>Comment utilisons-nous ces données ?</h4>
                    <p>
                        Nous utilisons principalement vos données personnelles dans le cadre de nos réponses â vos demandes de contact, expertise et d'estimation ou pour vous envoyer régulièrement nos actualités et des nouvelles sur nos ventes, nos opérations et nos offres susceptibles de susciter votre intérêt. Nous utilisons également vos données pour améliorer votre expérience digitale sur nos sites et contenus digitaux : comprendre l'intérêt que vous portez â leur contenu, gérer votre compte en ligne, faire en sorte que nos sites et supports digitaux soient présentés de la manière qui soit la plus adaptée â vous et â votre matériel numérique.
                    </p>
                </li>
                <li>
                    <h4>Comment pouvez-vous accéder â vos données personnelles ?</h4>
                    <p>
                        Vous avez toujours la possibilité de nous contacter par courrier postal, par courrier électronique ou par téléphone pour consulter les données personnelles vous concernant qui sont en notre possession. Pour cela, une adresse : 6 rue de Rennes - 35 530 Brécé ou accueil@mairie-brece.fr
                        Tous les renseignements recueillis par la commune de Brécé sont protégés en vertu de Loi n°78-17 du 6 janvier 1978 relative â l'informatique, aux fichiers et aux libertés. Conformément aux dispositions applicables, vous bénéficiez d'un droit d'accès, d'opposition, de rectification et de suppression des données qui vous concernent. Vous pouvez exercer ce droit â tout moment en adressant par exemple un courrier électronique, accompagné d'une photocopie d'une pièce d'identité, â l'adresse suivante : accueil@mairie-brece.fr
                    </p>
                </li>
                <li>
                    <h4>Partageons-nous vos données personnelles ?</h4>
                    <p>
                        Nous ne communiquons JAMAIS vos données personnelles â d'autres entreprises commerciales â moins que nous n'ayons préalablement obtenu votre consentement. Nous sommes susceptibles de communiquer vos données personnelles â certains prestataires de services (par exemple, nos agences digitales qui gèrent notre site Internet, certaines opérations en ligne ou les routages de newsletter). Nous demandons â ces prestataires de toujours agir en conformité avec les lois applicables en matière de protection de données personnelles et d'accorder une attention particulière â la confidentialité de ces données.
                    </p>
                </li>
                <li>
                    <h4>Ou stockons-nous vos données personnelles ?</h4>
                    <p>
                        Vos données personnelles sont stockées soit dans nos bases de données sur serveurs en interne, soit dans ceux de nos prestataires de services.                    </p>
                </li>
                <li>
                    <h4>Vos données personnelles sont-elles conservées en sécurité ?</h4>
                    <p>
                        Nous attachons une grande importance â la sécurisation des données. Nous avons pour objectif de toujours conserver vos données personnelles de la manière la plus sûre et la plus sécurisée, et uniquement pendant la durée nécessaire â la réalisation de la finalité poursuivie par le traitement. Dans cette perspective, nous prenons les mesures physiques et techniques appropriées pour empêcher leur altération ou leur perte mais surtout tout accès non autorisé â celles-ci.                    
                    </p>
                </li>
            </ol>
        </div>
    </div>
</section>