import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

const API = `${environment.api}/api/events`;

@Injectable({
  providedIn: 'root'
})
export class EvenementService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService, private ngbDateFormater: NgbDateParserFormatter) { }

  getEvents(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };    
    return this.http.get(API, httpOptions);
  }

  getOneEvent(title: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };    
    return this.http.get(API + "/" + title, httpOptions); 
  }

  getEventsByCategories(info: any):Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'Accept': '*/*' })
    };  
    console.log(info);
    
    var tabData = {};
    tabData = {
      'categorie': info.categorie,
      "dateDebut": this.ngbDateFormater.format(info.dateDebut),
      "dateFin": this.ngbDateFormater.format(info.dateFin),
    };
    const body = tabData;

    return this.http.post(API + '/research', body, httpOptions);
  }
}
