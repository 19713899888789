import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import { ActivatedRoute, Router } from '@angular/router';
import { ActualiteService } from 'src/app/_services/actualite.service';
import { TagService } from 'src/app/_services/tag.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';

@Component({
  selector: 'app-news-describe',
  templateUrl: './news-describe.component.html',
  styleUrls: ['./news-describe.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewsDescribeComponent implements OnInit {

  actualite: any;
  title: any;
  tags: any;
  categorieForm: any;

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private actualiteService: ActualiteService, private route: ActivatedRoute, private formbuilder: FormBuilder,
    private tagService: TagService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    this.getTags();
    this.title = this.route.snapshot.paramMap.get('slug')
    this.getActuByTitle();
    this.categorieForm = this.formbuilder.group({
      categorie: new FormControl('')
    });
  }

  getActuByTitle() {
    this.actualiteService.getOneActualite(this.title).subscribe(data => {
      this.actualite = data;
      this.commonService.changeData(data.texte_vignette);
      this.titleService.setTitle(data.titre);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: data.titre});
    });
  }

  getTags() {
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
    });
  }

  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl', centered:true});
    modalCreate.componentInstance.type = type;
  }

  onSubmit(): void {
    let info = {
      'categorie': this.categorieForm.get('categorie').value,
    }
    this.router.navigate(['actualites'], {queryParams: {'categorie': info.categorie}});
  }
}
