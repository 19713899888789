import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, EventEmitter} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

const API = `${environment.api}/api/actualites`;
@Injectable({
  providedIn: 'root'
})
export class ActualiteService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) { }

  getActualites(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };    
    return this.http.get(API, httpOptions);
  }

  getOneActualite(title: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };    
    return this.http.get(API + "/" + title, httpOptions); 
  }

  getActualitesResearch(info: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      })
    };    
    return this.http.get(API + "/research/" + info, httpOptions); 
  }
}
