import { Component, OnInit } from '@angular/core';
import {CommonService} from "../../_services/common.service";
import {Title} from "@angular/platform-browser";
import {NgDynamicBreadcrumbService} from "ng-dynamic-breadcrumb";
import {SocialAction} from "../../_models/socialAction";

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {
  socialActions: SocialAction[] = [
    {
      image: 'assets/images/toDelete/6xsg8lzh.png',
      name: 'Lorem ipsum',
      description: 'Lorem ipsum dolor sit amet',
      type: 'CCAS'
    },
    {
      image: 'assets/images/toDelete/6xsg8lzh.png',
      name: 'Lorem ipsum',
      type: 'SERVICES A MA PERSONNE',
      description: 'Lorem ipsum dolor sit amet'
    },
    {
      image: 'assets/images/toDelete/6xsg8lzh.png',
      name: 'Lorem ipsum',
      type: 'SANTÉ ET INFERMIÈRES',
      description: 'Lorem ipsum dolor sit amet'
    },
    {
      image: 'assets/images/toDelete/6xsg8lzh.png',
      name: 'Lorem ipsum',
      type: 'CLIC ALLIAGE',
      description: 'Lorem ipsum dolor sit amet'
    },
  ]

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }

  ngOnInit(): void {
    this.commonService.changeData("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus justo lectus, quis maximus mi tincidunt ut. Praesent sit amet malesuada nisi. Aenean nec eleifend ex, suscipit aliquam augue. Ut commodo eros justo, vestibulum congue nisl volutpat at. Donec molestie.");
    this.titleService.setTitle("Mon quotidien");
    this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: 'Mon quotidien'});
  }
}
