<section class="mt-5">
  <div class="container text-center text-lg-start">
    <img class="content-points" src="assets/icons/element-graphique-point.svg" />
    <div class="row content">
      <div class="col-lg-8">
        <div class="row-bootstrap">
          <div class="col-lg-12 partner my-3"  *ngFor="let partner of equipements">
              <h2 class="text-center equipment-title"> {{partner.nom}}</h2>
              <img class="img-fluid"  src="{{ partner.vignette}}"  />
              <div class="mt-3 equipment-describe">
                <div class="text-dark mb-2 text-justify " [innerHTML]="partner.description"></div>
              </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <app-quick-access></app-quick-access>
      </div>
    </div>
  </div>
</section>
