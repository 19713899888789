<app-search [show]="checked" (showToggle)="showToggle()"></app-search>
<nav class="navbar navbar-expand-lg ">
  <div class="container align-items-lg-start align-items-center pt-lg-3 pt-0">
    <a href="/" class="navbar-brand">
      <img src="/assets/images/logo.png" height="100" alt="CoolBrand" class="d-none d-lg-block">
      <img src="/assets/images/logo.png" height="50" alt="CoolBrand" class="d-block d-lg-none">
    </a>
    <div class="d-lg-none d-block">
      <a href="tel:+330299001009"><span class="text-white me-5">02 99 00 10 09</span></a>
      <button class="btn" (click)="showToggle()"><span class="fa fa-search  text-white"></span></button>
    </div>
    <button type="button" class="navbar-toggle d-block d-lg-none" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="fa fa-bars text-white"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">

      <div class="navbar-nav px-5">
        <li class="nav-item {{ null != item ? 'dropdown' : '' }}" *ngFor="let item of menu?.items">
          <a *ngIf="item.children != null" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {{ item.nom }}
          </a>
          <a *ngIf="item.children == null" class="nav-link" routerLink="{{menu.nom}}">
            {{ item.nom }}
          </a>

          <ul *ngIf="item.children != null" class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li  *ngFor="let submenu of item.children" class=" second-level" >
              <a class="dropdown-item" [routerLink]="['/'+item.slug, submenu.slug]">{{submenu.nom}}</a>
              <ul  *ngFor="let thirdmenu of submenu.children"  >
                <li *ngIf="thirdmenu.page !== null && thirdmenu.lien === null" class="third-level"><a class="text-decoration-none" [routerLink]="[item.slug, submenu.slug, thirdmenu.slug|lowercase]">{{thirdmenu.nom}}</a></li>
                <li *ngIf="thirdmenu.lien !== null" class="third-level"><a class="text-decoration-none" [routerLink]="['/'+(thirdmenu.lien|lowercase)]">{{thirdmenu.nom}}</a></li>
              </ul>
            </li>
          </ul>

        </li>
      </div>

      <div class="navbar-nav ms-auto align-items-center d-none d-lg-block">
        <a href="/contact" class="btn btn-dark me-5">Contactez-nous</a>
        <a class="phone-link" href="tel:+330299001009"><span class="text-white">02 99 00 10 09</span></a>
        <button class="btn" (click)="showToggle()"><span class="fa fa-search ms-5 text-white"></span></button>
      </div>

    </div>
  </div>
</nav>
<router-outlet></router-outlet>
