import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { AssociationsService } from 'src/app/_services/associations.service';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-details-association',
  templateUrl: './details-association.component.html',
  styleUrls: ['./details-association.component.scss']
})
export class DetailsAssociationComponent implements OnInit {
  asso: any;
  title: any;
  constructor(private association: AssociationsService, private commonService: CommonService, private titleService: Title,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService, private route: ActivatedRoute) {
      this.title = this.route.snapshot.paramMap.get('slug')
    }

  ngOnInit(): void {
    this.getAssos();
  }

  getAssos() {
    this.association.getOne(this.title).subscribe(data => {
      this.asso = data;
      this.commonService.changeData("");
      this.titleService.setTitle(data['nom']);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: data['nom']});
    })
  }
}
