<section class="mt-5">
    <div class="mentions-container">
        <div class="titre">
            <h1 class="mentions-titre">Site Officiel de la municipalité de Brécé - Ille et Vilaine (35)<br><br></h1>
            <h2 class="mentions-disclaimer">Cette page précise les mentions légales qui s'appliquent à tout visiteur de ce site. <br><br></h2>
        </div>
        <b class="responsable">Responsable d'édition : Monsieur Joël Bouvet - Maire</b>
        <br>
        <b>Le site Internet www.mairie-brece.fr est hébergé par la société : <br><br>
            <p class="hebergeur">
                Agence 11H10 <br>
                Parc d'activités BEAUJARDIN<br>
                35410 - Châteaugiron<br>
                Tél. : <a class="phone" href="tel:+33299419676"> 02 99 41 96 76</a><br><br>
            </p>
        </b>

        <p class="mentions-texte">
            Conformément à la loi Informatique et Liberté de 1978, vous disposez d'un droit d'accès et de modification aux données vous concernant. 
            Ce droit peut être exercé par courrier électronique à accueil@mairie-brece.fr ou par courrier à : <br>
        </p>
        <p class="adresse">
            6 rue de Rennes <br>
            35 530 Brécé <br>
            Tél : 02 99 00 10 09 <br>
            Fax : 02 99 00 24 29 <br>
        </p>
        <p class="mentions-texte">
            Toutes les images, photos, illustrations et mises en page figurant sur ce site Internet sont la propriété exclusive de la 
            municipalité de Brécé et Ayants-Droits. Toute exploitation de celles-ci à des fins commerciales utilisées sans son consentement 
            express est interdite, et fera systématiquement l’objet de poursuites. La municipalité de Brécé ne pourrait être en 
            aucun cas tenue pour responsable du contenu des sites de ses partenaires.
        </p>
    </div>
</section>