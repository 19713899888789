import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mes-loisirs',
  templateUrl: './mes-loisirs.component.html',
  styleUrls: ['./mes-loisirs.component.scss']
})
export class MesLoisirsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
