import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { ActualiteService } from 'src/app/_services/actualite.service';
import { CommonService } from 'src/app/_services/common.service';
import { IntraMurosService } from 'src/app/_services/intra-muros.service';
import { TagService } from 'src/app/_services/tag.service';
import {DomSanitizer, Title} from "@angular/platform-browser";
import { PropositionFormComponent } from 'src/app/proposition-form/proposition-form.component';

@Component({
  selector: 'app-news-im-describe',
  templateUrl: './news-im-describe.component.html',
  styleUrls: ['./news-im-describe.component.scss']
})
export class NewsImDescribeComponent implements OnInit {

  actualite: any;
  title: any;
  id: any;
  url: any = [];
  tags: any;
  sliders: any = [];
  categorieForm: any;
  slideConfig: any;

  constructor(private commonService: CommonService, private titleService: Title, private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private intraMurosService: IntraMurosService, private route: ActivatedRoute, private formbuilder: FormBuilder,
    private tagService: TagService, private modalService: NgbModal, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getTags();
    this.title = this.route.snapshot.paramMap.get('slug');
    this.id = this.route.snapshot.paramMap.get('id');
    this.getNews();
    this.categorieForm = this.formbuilder.group({
      categorie: new FormControl('')
    });

    this.slideConfig = {
      autoplay: true,
      autoplaySpeed: 3000,
      prevArrow: '.prev-event-caroussel',
      nextArrow: '.next-event-caroussel',
      draggable: true,
      dots: true,
      infinite: true,
      arrows: true
    }
  }

  sanitized(link: string) {
    let safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(link);
    return safeUrl;
  }

  getNews() {
    this.intraMurosService.getNew(this.id).subscribe(data => {
      this.actualite = data;
      if (this.actualite.url1 !== '') {
        this.url.push(this.actualite.url1);
      }
      if (this.actualite.url2 !== '') {
        this.url.push(this.actualite.url2);
      }
      if (this.actualite.url3 !== '') {
        this.url.push(this.actualite.url3);
      }

      this.commonService.changeData(data.texte_vignette);
      this.titleService.setTitle(data.title);
      this.ngDynamicBreadcrumbService.updateBreadcrumbLabels({customTitle: data.title});
      this.sliders.push(this.actualite.image);
      this.actualite.gallery.forEach(element => {
        this.sliders.push(element['url']);
      });

      this.actualite.videos.forEach(element => {
        element = "https://www.youtube.com/embed/"+element['id'];
        this.sliders.push(element);
      });
    })
  }

  getTags() {
    this.tagService.getTags().subscribe(data => {
      this.tags = data;
    });
  }

  openPopUpPage(type: string): void{
    const modalCreate = this.modalService.open(PropositionFormComponent, {size: 'xl', centered:true});
    modalCreate.componentInstance.type = type;
  }

  onSubmit(): void {
    let info = {
      'categorie': this.categorieForm.get('categorie').value,
    }
    this.router.navigate(['actualites'], {queryParams: {'categorie': info.categorie}});
  }
}
