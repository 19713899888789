import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from '../_services/contact.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-proposition-form',
  templateUrl: './proposition-form.component.html',
  styleUrls: ['./proposition-form.component.scss']
})
export class PropositionFormComponent implements OnInit {
  @Input() public type: any;

  propositionForm: any;
  errorMessage = '';
  title: any;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private contactService: ContactService) { }

  get f() { return this.propositionForm.controls; }

  ngOnInit(): void {
    switch (this.type) {
      case 'actualite':
        this.title = 'Proposer une actualité';
        break;
      case 'evenement':
        this.title = 'Proposer un évènement';
        break;
      default:
        break;
    }

    this.propositionForm = this.formBuilder.group({
      titre: new FormControl(''),
      description: new FormControl(''),
      nom: new FormControl(''),
      prenom: new FormControl(''),
      mail: new FormControl(''),
      tel: new FormControl(''),
    })
  }

  onSubmit() {
    this.contactService.sendProposition(this.type, this.propositionForm.value).subscribe(data => {
      Swal.fire({
        toast: true,
        title: 'Votre proposition a bien été prise en compte',
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        icon: 'success',
        timerProgressBar: true
      });
    },
    err => {
      Swal.fire({
        toast: true,
        title: 'Erreur',
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        icon: 'error',
        timerProgressBar: true
      })
    });
    // TODO: NOTIFICATION!
  }
}
